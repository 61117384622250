<template>
  <div class="yujiao">
    <heads />
    <div class="yujiao-src">
      <div class="bunner">
        <div class="left">
          <div class="left-font"><img :src="yjfont"></div>
          <div class="left-fontA">依据各版本义务教育英语教科书编制而成，针对英语学科学生需要掌握的听、说、读、写四项基本技能，匹配优质、丰富的音视频及交互式数字资源，是培养学生英语学科核心素养不可或缺的基础性学科资源。</div>
          <div>
          <a :href="downUrlPC"
            :download="downloadYujiao.name"
            class="download">
            <el-button class="button"
              type="primary">
              <span class="span-button-font"><img :src="buttonimg"
                  class="but-img">下载客户端</span>
            </el-button>
            </a>
            <div class="popo">
              <el-popover
                placement="bottom"
                width="180"
                height="180"
                trigger="hover">
                <img :src="appImg" class="appImg">
                <el-button slot="reference" class="buttonA"
                  type="primary">
                  <span class="span-button-font"><img :src="dow"
                      class="but-img">下载APP</span>
                </el-button>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="right">
          <img :src="yujiaoimg">
        </div>
      </div>
    </div>
    <div class="yj-banner-src">
      <div class="dataTitle">{{dataTitle}}</div>
      <div class="dataArticle">{{dataArticle}}</div>
      <div class="data-article">
        <el-row>
          <div class="data-cle"><img :src="ting"
              class="data-Img">
            <div class="data-heading">听</div>
          </div>
          <div class="data-cleA"><img :src="shuo"
              class="data-Img">
            <div class="data-heading">说</div>
          </div>
          <div class="data-cleA"><img :src="du"
              class="data-Img">
            <div class="data-heading">读</div>
          </div>
          <div class="data-cleA"><img :src="chang"
              class="data-Img">
            <div class="data-heading">唱</div>
          </div>
          <div class="data-cleA"><img :src="cihui"
              class="data-Img">
            <div class="data-heading">词汇表</div>
          </div>
          <div class="data-cleA"><img :src="biaoda"
              class="data-Img">
            <div class="data-heading">表达法</div>
          </div>
        </el-row>
      </div>
    </div>
    <div class="evaluate">
      <div class="evaluate-title">激活码获取资源，灵活使用</div>
      <div class="evaluate-Article">{{evaluateArticle}}</div>
      <img :src="jhmImg"
        class="evaluate-Img">
    </div>
    <foots />
    <!-- <contant /> -->
  </div>
</template>
<script>
import { get } from 'util/request';
import _ from 'lodash';
import { dateFormat } from 'util/ui';
import ting from '../assets/img/index/ting.png';
import shuo from '../assets/img/index/shuoimg.png';
import du from '../assets/img/index/duimg.png';
import chang from '../assets/img/index/chang.png';
import cihui from '../assets/img/index/cihui.png';
import biaoda from '../assets/img/index/biaodaimg.png';
import heads from './common/head';
import foots from './common/foot';
// import contant from './common/contant';


import yujiaoimg from '../assets/img/index/yujiaoimg.png';
import buttonimg from '../assets/img/index/buttonimg.png';
import dow from '../assets/img/index/dow.png';
import jhmImg from '../assets/img/index/jhmImg.png';
import yjfont from '../assets/img/index/yjfont.svg';
import appImg from '../assets/img/index/szjc.png';

export default {
  components: {
    heads,
    foots,
    // contant,
  },
  data() {
    return {
      appImg,
      dow,
      yjfont,
      ting,
      shuo,
      chang,
      du,
      cihui,
      biaoda,
      buttonimg,
      yujiaoimg,
      jhmImg,
      downloadYujiao: window.config.downloadYujiao,
      // 其它版本
      downloadOther1: window.config.downloadOther1,
      downloadOther2: window.config.downloadOther2,
      downloadOther3: window.config.downloadOther3,
      downloadOther4: window.config.downloadOther4,
      downUrlPC: '',
      versionPC: '',
      sizePC: '',
      dataTitle: '帮助提升学生英语语音与听说能力',
      dataArticle:
        '涵盖教科书中要求听、说、读、唱等教学内容，以及附录中的单元词汇表和常用表达法等，全部由专业人士录音，语音规范标准',

      evaluateArticle: '为充分适应信息化教学特点，满足课堂教学和学生学习需要，师生可在客户端通过激活码获取和下载资源，灵活使用',
      downData: window.config,
    };
  },
  created() {
    const num = _.parseInt(Math.random(0, 1) * 1000);
    get(`${this.downloadYujiao.urlinfo}?${num}`).then(({ data, err }) => {
      if (!err) {
        const { version, size, date } = data;
        this.versionPC = version;
        this.sizePC = (size / 1024 / 1024).toFixed(1);
        this.updateTimePC = dateFormat(date, 'YYYY-MM-DD');
        this.downUrlPC = `${this.downloadYujiao.downUrl}${version}/${this.downloadYujiao.name}`;
      }
    });
  },
  methods: {
  },
};
</script>

<style scope lang="less">
.el-popover{
  box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.05);
  border-radius: 16px;
}
.appImg{
  width: 180px;
  height: 180px;
}
.yujiao {
  .yujiao-src {
    background: url(../assets/img/index/yujiaoBunner.png) center;
    margin-top: 143px;
    text-align: center;
    width: 100%;
    height: 600px;
    .bunner {
      width: 1200px;
      margin: auto;
      .left {
        width: 488px;
        float: left;
        .left-font {
          height: 80px;
          font-size: 80px;
          font-family: AlimamaFangYuanTiVF-SemiBold-Round, AlimamaFangYuanTiVF-SemiBold;
          font-weight: normal;
          color: #009b5f;
          margin-top: 116px;
          text-align: left;
        }
        .left-fontA {
          width: 488px;
          height: 112px;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #262626;
          margin-top: 60px;
          text-align: left;
        }
        .button {
          width: 240px;
          height: 64px;
          background: #009b5f;
          border-radius: 32px;
          border: none;
          margin-top: 56px;
          float: left;
          .span-button-font {
            font-size: 24px;
            color: #ffffff;
            font-weight: 400;
            margin-right: 36px;
            .but-img {
              margin-right: 23px;
              margin-top: 2px;
              float: left;
              margin-left: 21px;
            }
          }
        }
        .buttonA {
          margin-left: 16px;
          width: 208px;
          height: 64px;
          background: #FFFFFF;
          border-radius: 32px;
          border: none;
          margin-top: 56px;
          float: left;
          .span-button-font {
            font-size: 24px;
            color: #009B5F;
            font-weight: 400;
            margin-right: 36px;
            .but-img {
              margin-right: 23px;
              margin-top: 2px;
              float: left;
              margin-left: 21px;
            }
          }
        }
      }
      .right {
        width: 632px;
        float: right;
        margin-top: 116px;
      }
    }
  }
  .yj-banner-src {
    text-align: center;
    height: 486px;
    .dataTitle {
      padding-top: 80px;
      margin: 0px auto;
      font-weight: 500;
      font-size: 42px;
      color: #262626;
    }
    .dataArticle {
      margin: 16px auto 0px;
      font-weight: 400;
      font-size: 20px;
      color: #262626;
    }
    .data-article {
      margin: 80px auto 0px;
      width: 1200px;
      .data-cle {
        float: left;
      }
      .data-cleA {
        float: left;
        margin-left: 120px;
      }
      .data-Img {
        width: 100px;
        height: 100px;
      }
      .data-heading {
        margin-top: 16px;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
      }
    }
  }
  .evaluate {
    width: 100%;
    height: 754px;
    background: rgba(250, 250, 250, 1);
    text-align: center;
    .evaluate-title {
      font-size: 42px;
      color: #262626;
      font-weight: 500;
      padding-top: 80px;
    }
    .evaluate-Article {
      font-size: 20px;
      margin: 16px auto 0px;
      width: 1210px;
      color: #262626;
    }
    .evaluate-Img {
      margin-top: 80px;
      width: 1000px;
      height: 412px;
    }
  }
}
</style>
