var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yujiao" },
    [
      _c("heads"),
      _c("div", { staticClass: "yujiao-src" }, [
        _c("div", { staticClass: "bunner" }, [
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "left-font" }, [
              _c("img", { attrs: { src: _vm.yjfont } })
            ]),
            _c("div", { staticClass: "left-fontA" }, [
              _vm._v(
                "依据各版本义务教育英语教科书编制而成，针对英语学科学生需要掌握的听、说、读、写四项基本技能，匹配优质、丰富的音视频及交互式数字资源，是培养学生英语学科核心素养不可或缺的基础性学科资源。"
              )
            ]),
            _c("div", [
              _c(
                "a",
                {
                  staticClass: "download",
                  attrs: {
                    href: _vm.downUrlPC,
                    download: _vm.downloadYujiao.name
                  }
                },
                [
                  _c(
                    "el-button",
                    { staticClass: "button", attrs: { type: "primary" } },
                    [
                      _c("span", { staticClass: "span-button-font" }, [
                        _c("img", {
                          staticClass: "but-img",
                          attrs: { src: _vm.buttonimg }
                        }),
                        _vm._v("下载客户端")
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "popo" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom",
                        width: "180",
                        height: "180",
                        trigger: "hover"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "appImg",
                        attrs: { src: _vm.appImg }
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "buttonA",
                          attrs: { slot: "reference", type: "primary" },
                          slot: "reference"
                        },
                        [
                          _c("span", { staticClass: "span-button-font" }, [
                            _c("img", {
                              staticClass: "but-img",
                              attrs: { src: _vm.dow }
                            }),
                            _vm._v("下载APP")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "right" }, [
            _c("img", { attrs: { src: _vm.yujiaoimg } })
          ])
        ])
      ]),
      _c("div", { staticClass: "yj-banner-src" }, [
        _c("div", { staticClass: "dataTitle" }, [
          _vm._v(_vm._s(_vm.dataTitle))
        ]),
        _c("div", { staticClass: "dataArticle" }, [
          _vm._v(_vm._s(_vm.dataArticle))
        ]),
        _c(
          "div",
          { staticClass: "data-article" },
          [
            _c("el-row", [
              _c("div", { staticClass: "data-cle" }, [
                _c("img", {
                  staticClass: "data-Img",
                  attrs: { src: _vm.ting }
                }),
                _c("div", { staticClass: "data-heading" }, [_vm._v("听")])
              ]),
              _c("div", { staticClass: "data-cleA" }, [
                _c("img", {
                  staticClass: "data-Img",
                  attrs: { src: _vm.shuo }
                }),
                _c("div", { staticClass: "data-heading" }, [_vm._v("说")])
              ]),
              _c("div", { staticClass: "data-cleA" }, [
                _c("img", { staticClass: "data-Img", attrs: { src: _vm.du } }),
                _c("div", { staticClass: "data-heading" }, [_vm._v("读")])
              ]),
              _c("div", { staticClass: "data-cleA" }, [
                _c("img", {
                  staticClass: "data-Img",
                  attrs: { src: _vm.chang }
                }),
                _c("div", { staticClass: "data-heading" }, [_vm._v("唱")])
              ]),
              _c("div", { staticClass: "data-cleA" }, [
                _c("img", {
                  staticClass: "data-Img",
                  attrs: { src: _vm.cihui }
                }),
                _c("div", { staticClass: "data-heading" }, [_vm._v("词汇表")])
              ]),
              _c("div", { staticClass: "data-cleA" }, [
                _c("img", {
                  staticClass: "data-Img",
                  attrs: { src: _vm.biaoda }
                }),
                _c("div", { staticClass: "data-heading" }, [_vm._v("表达法")])
              ])
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "evaluate" }, [
        _c("div", { staticClass: "evaluate-title" }, [
          _vm._v("激活码获取资源，灵活使用")
        ]),
        _c("div", { staticClass: "evaluate-Article" }, [
          _vm._v(_vm._s(_vm.evaluateArticle))
        ]),
        _c("img", { staticClass: "evaluate-Img", attrs: { src: _vm.jhmImg } })
      ]),
      _c("foots")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }